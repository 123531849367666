
/**
 * Validador de  senha:
 **  No mínimo 8 caracteres
 ** Letras maiúsculas e minúsculas
 ** Pelo menos 1 número
 ** Pelo menos 1 caracter especial
 ** ...more
 */

 export class ValidatePassword {
    watchPassword: string = "";
    /**
     *
     * @param password   senha a ser observada
     */
    constructor(password: string) {
      this.watchPassword = password;
    }
  
    minLength() {
      if (this.watchPassword.trim().length < 8) {
        return false;
      }
      return true;
    }
    // upperAndLowerCase() {
    //   const str = this.watchPassword;
    //   return Boolean(str.match(/[A-Z]/)) && Boolean(str.match(/[a-z]/));
    // }
    containNumber() {
      const str = this.watchPassword;
      return Boolean(str.match(/[0-9]/));
    }
  
    containsSpecialChars() {
      const str = this.watchPassword;
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }
  
    isAllValid() {
      // return boolean
      return (
        this.minLength() &&
        // this.upperAndLowerCase() &&
        this.containNumber() &&
        this.containsSpecialChars()
      );
    }
  }
  