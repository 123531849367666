import React, { useRef } from 'react';
import styled from 'styled-components';
import { ContainerStyled } from './styles';

type IProps = React.AllHTMLAttributes<HTMLInputElement> & {
  children: JSX.Element;
  showError?: boolean;
  erroMessage?: any;
  label: string;
  id?: string;
};

function Input({ children, showError = false, erroMessage, label, id, ...rest }: IProps) {
  const borderColor = !showError ? '#ced4da' : '#dc3545';

  return (
    <ContainerStyled showError={showError} borderColor={borderColor}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>

      {children}

      {erroMessage && (
        <div className="erro__container">
          <span>{String(erroMessage)}</span>
        </div>
      )}
    </ContainerStyled>
  );
}

export default Input; //React.memo(Input);
