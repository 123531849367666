import styled from "styled-components";
import { theme } from "./globalStyles";
import bg from "@login-old/assets/images/bg-blue.svg";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-color: ${colors.primaryColor};
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
  .fs-forgot-password {
    margin-top: 16px;
    span {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .spl-btn-back {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;

    margin-bottom: 24px;

    span {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .bv-lf-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${theme.primaryDark};
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px 24px;
  border-radius: 16px;
  background-color: white;

  @media (max-width: 768px) {
    padding: 32px 16px;
  }
`;
