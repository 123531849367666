import BVUtilsOld from '@bv/utils-old';
import bvStyleguide from '@bv/styleguide';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './layouts/Login';
import RegisterNewPassword from './layouts/RegisterNewPassword';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/redefinir-senha',
      element: <RegisterNewPassword />,
    },
  ],
  {
    basename: '/login',
  },
);

export default function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // logout provisório
    try {
      window.localStorage.clear();
      BVUtilsOld.cookiesHelper.clearAuthCookies();
      console.log('localStorage e cookies limpos');
    } catch (error) {
      console.log('Erro ao limpar localStorage e cookies', error);
    }

    const token = localStorage.getItem('token');

    if (!token) setIsReady(true);
  }, []);

  if (!isReady) {
    return <bvStyleguide.PageLoader />;
  }

  return (
    <section className="login">
      <ToastContainer position="top-right" autoClose={5000} theme="light" />

      <RouterProvider router={router} />
    </section>
  );
}
