import styled from "styled-components";
import { theme } from "@login-old/styles/globalStyles";

export const Container = styled.div`
  width: 100%;

  h2 {
    color: ${theme.primaryDark};

    font-size: max(16px, 1.2vw);
    font-weight: 500;
    margin: 24px 0;
  }
`;
