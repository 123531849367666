import BVUtils from '@bv/utils';
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.BV_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  function (response) {
    return response.data.data;
  },
  function (err) {
    if (err?.response?.data?.messages) {
      BVUtils.request.requestErrorToastHandler(err.response.data.messages);
      return Promise.reject(err.response.data.messages);
    }

    if (err?.response?.data?.message) {
      BVUtils.request.requestErrorToastHandler(err.response.data.message);
      return Promise.reject(err.response.data.message);
    }

    return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
  },
);
