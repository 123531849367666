import styled from "styled-components";
import { theme } from "@login-old/styles/globalStyles";
import BVUtilsOld from "@bv/utils-old";

const { colors } = BVUtilsOld;

export const Container = styled.div`
  width: 100%;
`;

export const DescriptionStyled = styled.div`
  color: ${theme.primaryDark};
  font-size: max(16px, 1.2vw);
  font-weight: 500;
`;

export const NameStyled = styled.div`
  color: ${theme.primaryDark};

  font-size: max(18px, 1.3vw);
  font-weight: 700;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const FieldPassword = styled.div<{
  showError: boolean;
  matchPassword?: boolean;
  notMatchPassword?: boolean;
}>`
  position: relative;

  > div {
    border-color: ${({ matchPassword, theme }) =>
      matchPassword ? theme.borders.borderSuccess + " !important" : ""};
    border-color: ${({ notMatchPassword, theme }) =>
      notMatchPassword ? theme.borders.borderError : ""} !important;

    label span {
      /* if deu match */
      background: ${({ theme, matchPassword }) =>
        matchPassword ? "none" : ""} !important;
      -webkit-text-fill-color: ${({ theme, matchPassword }) =>
        matchPassword ? theme.borders.borderSuccess : ""} !important;
      text-fill-color: ${({ theme, matchPassword }) =>
        matchPassword ? theme.borders.borderSuccess : ""} !important;

      /* if nao deu match */
      background: ${({ theme, notMatchPassword }) =>
        notMatchPassword ? "none" : ""} !important;
      -webkit-text-fill-color: ${({ theme, notMatchPassword }) =>
        notMatchPassword ? theme.borders.borderError : ""} !important;
      text-fill-color: ${({ theme, notMatchPassword }) =>
        notMatchPassword ? theme.borders.borderError : ""} !important;
    }

    :before {
      /* background: none !important; */
      background: ${({ matchPassword, notMatchPassword }) =>
        matchPassword || notMatchPassword ? "none !important" : ""};
    }
  }
  > button {
    position: absolute;
    top: 0;
    right: 16px;
    right: ${({ theme, showError }) => (showError ? "40px" : "16px")};
    display: flex;
    align-items: center;
    transform: translate(0, 50%);
    top: 22px;
    right: 14px;
  }
`;
