import styled, { StyledComponent } from "styled-components";
import { theme } from "@login-old/styles/globalStyles";
import { devices } from "@shared/styles/sizesMediaQueries";

export const Container: StyledComponent<"div", any> = styled.div`
  width: 100%;

  h1 {
  }

  h2 {
    color: ${theme.primaryDark};
    font-size: max(16px, 1.2vw);
    font-weight: 400;
    margin: 24px 0;
  }
`;
