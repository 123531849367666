import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import FirstStepLogin from '../../components/FirstStepLogin';
import SecondStepLogin from '../../components/SecondStepLogin';
import { AppNameStyled } from './styles';

import StepEmailSent from '@login-old/components/StepEmailSent';
import StepForgotPassword from '@login-old/components/StepForgotPassword';
import { Card, MainContainer } from '@login-old/styles/common';
import GlobalStyle from '@login-old/styles/globalStyles';
import BVUtilsOld from '@bv/utils-old';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Login: React.FC = () => {
  const [username, setUserName] = useState('');
  const [user, setUser] = useState(null);
  const [emailSent, setEmailSent] = useState('');
  const [step, setStep] = useState<'firstStepLogin' | 'secondStepLogin' | 'forgotPassword' | 'emailSentForgotPassword'>('firstStepLogin');
  const navigate = useNavigate();

  const { checkSSO, isLoading, isSSO, ssoSignIn, sooInfo } = BVUtilsOld.hooks.useSSO(navigate);

  useLayoutEffect(() => {
    const ACCESS_TOKEN: string | null = BVUtilsOld.cookiesHelper.getAccessToken();

    if (ACCESS_TOKEN) {
      window.location.href = '/';
    }
  }, []);
  useEffect(() => {
    if (window.HubSpotConversations) {
      setTimeout(() => {
        window.HubSpotConversations.resetAndReloadWidget();
      }, 1000);
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bem-vindo(a) ao BV </title>
      </Helmet>

      <GlobalStyle />
      <MainContainer>
        <AppNameStyled>Benefício Visão</AppNameStyled>
        <Card
          className="mb-4 mt-5"
          style={{
            paddingBottom: step == 'firstStepLogin' || step == 'secondStepLogin' ? 16 : '',
          }}>
          {step == 'firstStepLogin' && (
            <FirstStepLogin checkSSO={checkSSO} isLoading={isLoading} setUserName={setUserName} setUser={setUser} setStep={setStep} isSSO={isSSO} />
          )}
          {step == 'secondStepLogin' && user && (
            <SecondStepLogin
              user={user}
              username={username}
              setUser={setUser}
              setStep={setStep}
              isSSO={isSSO}
              ssoSignIn={ssoSignIn}
              sooInfo={sooInfo}
            />
          )}

          {step == 'forgotPassword' && <StepForgotPassword setStep={setStep} setEmailSent={setEmailSent} />}

          {step == 'emailSentForgotPassword' && <StepEmailSent setStep={setStep} emailSent={emailSent} />}
        </Card>
      </MainContainer>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
