import React from 'react';
import * as C from './styles';
import { StepEmailSentProps } from './types';

const StepEmailSent: React.FC<StepEmailSentProps> = ({ emailSent, setStep }: StepEmailSentProps) => {
  return (
    <C.Container>
      <h2 className="bv-lf-title">Te enviamos um e-mail</h2>

      <div className="bv-lf-content">
        <p>
          Enviamos um e-mail de redefinição de senha para:
          <br />
          <br />
          <strong>{emailSent}</strong>
          <br />
          <br />
          Abra sua caixa de e-mail e redefina sua senha.
        </p>
      </div>
      <C.WrapperButton>
        <C.ButtonStyled onClick={() => setStep('firstStepLogin')}>Voltar</C.ButtonStyled>
      </C.WrapperButton>
    </C.Container>
  );
};

export default StepEmailSent;
