import styled, { css } from "styled-components";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export const AppNameStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 800;
  font-size: 28px;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: normal;
  }
`;

export const SpinnerStyled = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: -2px;
`;

export const ButtonStyled = styled(Button)`
  width: 700px;
`;
