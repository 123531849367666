// globalStyles.js
export const theme = {
  primary: '#2845DC',
  primaryDark: '#133474',
  danger: '#DC3545',
  titleColor: '#152536',
  darkBlue: '#0c1d59',
  burntOrange: '#FD7E14',
  blue100: '#CFE2FF',
};

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Inter', sans-serif;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6{
        padding: 0;
        margin: 0;
      }
    }
  

  button{
    border: 0;
    background-color: transparent;
  }


    // override bootstrap
  .btn-primary{
    background: ${theme.primary};
    border-color: ${theme.primary};
  }
  .btn-outline-primary{
    border-color: ${theme.primary};
    color: ${theme.primary};
    border-width: 2px;

    &:hover{
     background-color: ${theme.primary};
     border-color: ${theme.primary};
    }
  }

  .form-check-input:checked{
    background-color: ${theme.primary};
    border-color: ${theme.primary};
  }

`;

export default GlobalStyle;
