import styled from "styled-components";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;

export const ContainerStyled = styled.div`
  @media (max-width: 600px) {
    .i-btn-finish {
      margin-top: 32px;
    }
  }
`;

export const AreaPasswordRules = styled.ul`
  background: #f8f9fa;

  border: 1px solid #9ec5fe;
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;
