import styled from "styled-components";

type TContainerProps = {
  showError: boolean;
  borderColor: string;
};

export const ContainerStyled = styled.div<TContainerProps>`
  /* max-width: 300px; */
  /* border: 2px solid #dcdcdc;
    border-color: ${({ showError }) =>
    showError ? "ref" + " !important" : ""};
    border-radius: 12px;
    */
  position: relative;

  display: flex;

  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  padding-bottom: 16px;

  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border: 1.5px solid ${({ borderColor }) => borderColor};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ::placeholder {
    }

    :focus {
      color: #212529;
      background-color: #fff;
      border-color: ${({ showError, borderColor }) =>
        !showError ? "#86b7fe" : borderColor};
      outline: 0;
      box-shadow: ${({ showError }) =>
        !showError
          ? "0 0 0 0.25rem rgba(13, 110, 253, 0.25)"
          : // : "0 0 0 0.25rem rgba(220,53,69,.25)"};
            ""};
    }
  }

  .erro__container {
    display: flex;
    position: absolute;
    bottom: -20px;
    span {
      font-size: 0.875em;
      color: #dc3545;
    }
  }

  label {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(12, 29, 89);
  }
`;
