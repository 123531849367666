import React from 'react';
import BVUtilsOld from '@bv/utils-old';
import BVUtils from '@bv/utils';
import BVComponentsOld from '@bv/components-old';
import { useForm } from 'react-hook-form';
import * as C from './styles';

import InputField from '@login-old/components/InputField';
import { REGEX_EMAIL } from '@login-old/constants';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GetPreLoginResponse, preLoginService } from '@login-old/services/auth.service';

const { ButtonLoading } = BVComponentsOld;

const { api } = BVUtilsOld;

type FirstStepLoginProps = {
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<any>;
  setStep: React.Dispatch<React.SetStateAction<'firstStepLogin' | 'secondStepLogin' | 'forgotPassword' | 'emailSentForgotPassword'>>;
  checkSSO: (email: string) => Promise<any>;
  isLoading: boolean;
  isSSO: boolean;
};
const FirstStepLogin = ({ setUser, setUserName, setStep, checkSSO, isLoading, isSSO }: FirstStepLoginProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function getUserByEmail(dataForm) {
    const { email } = dataForm;
    const sso = await checkSSO(email);
    if (sso?.status == 'pending') {
      return;
    }
    setUserName(email);
    try {
      const recaptchaToken = await executeRecaptcha('PRE_LOGIN');

      const data  = (await preLoginService(email, recaptchaToken)) as any;
      if (data?.tokenResetPassword) {
        navigate('/redefinir-senha?token=' + data.tokenResetPassword);
        return;
      }
      console.log({data});

      setUser(data);
      setStep('secondStepLogin');
    } catch (err) {
      console.log({ err });
      if (err?.messages){
        err.messages.map((message) => BVUtils.toastHandler({ type: 'error', message }));
      }else{
        const message = err?.response?.data?.message || err?.message || 'Erro';
        BVUtils.toastHandler({ type: 'error', message });
      }
    }
  }

  return (
    <C.Container>
      <h1 className="bv-lf-title">Realize seu login</h1>
      <h2>
        Insira seu e-mail cadastrado no <strong>Benefício Visão</strong>
      </h2>
      <form onSubmit={handleSubmit(getUserByEmail)}>
        <div className="row">
          <div className="col-12">
            <InputField label="E-mail" showError={!!errors?.email?.type}>
              <input autoFocus type="email" placeholder="Seu e-mail" {...register('email', { required: true, pattern: REGEX_EMAIL })} />
            </InputField>
          </div>
          <div className="col-12">
            <ButtonLoading
              type="submit"
              loading={isSubmitting || isLoading}
              disabled={isSubmitting || isLoading}
              className="btn btn-primary w-100 flex items-center justify-center text-center">
              Avançar
            </ButtonLoading>
          </div>

          <div className="col-12 fs-forgot-password text-center">
            <button className="m-0 p-0" type={'button'} onClick={() => setStep('forgotPassword')}>
              <span className="text-primary">Esqueci minha senha</span>
            </button>
          </div>
        </div>
      </form>
    </C.Container>
  );
};

export default FirstStepLogin;
