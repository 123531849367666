import React, { useEffect, useState } from 'react';
import BVComponentsOld from '@bv/components-old';
import BVUtilsOld from '@bv/utils-old';
import BVUtils from '@bv/utils';
import { useForm } from 'react-hook-form';
import * as C from './styles';

import InputField from '@login-old/components/InputField';
import { REGEX_EMAIL } from '@login-old/constants';
import arrowToLeftAsset from '@login-old/assets/images/icons/arrow-to-left.svg';

const { api } = BVUtilsOld;
const { Button } = BVComponentsOld;

type StepForgotPasswordProps = {
  setStep: React.Dispatch<React.SetStateAction<'firstStepLogin' | 'secondStepLogin' | 'forgotPassword' | 'emailSentForgotPassword'>>;
  setEmailSent: React.Dispatch<React.SetStateAction<string>>;
};
const StepForgotPassword = ({ setStep, setEmailSent }: StepForgotPasswordProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(data) {
    const { email } = data;
    try {
      const response = await api.authApi.preRedefinePassword(email);
      setEmailSent(email);
      setStep('emailSentForgotPassword');
    } catch (error: any) {
      console.error('error: ', error);
      const message = error?.data?.message || 'Erro!';
      BVUtils.toastHandler({ type: 'error', message });
    }
  }

  return (
    <C.Container>
      <button className="spl-btn-back" onClick={() => setStep('firstStepLogin')}>
        <img src={arrowToLeftAsset} alt="arrow-to-left" width={24} height={24} />

        <span className="text-primary">Voltar </span>
      </button>

      <h1 className="bv-lf-title">Redefinição de senha</h1>
      <h2>
        Insira seu e-mail cadastrado no <strong>Benefício Visão</strong> para redefinir sua senha
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <InputField label="E-mail" showError={!!errors?.email?.type}>
              <input autoFocus type="email" placeholder="Seu e-mail" {...register('email', { required: true, pattern: REGEX_EMAIL })} />
            </InputField>
          </div>
          <div className="col-12">
            <Button type="submit" variant="primary" loading={isSubmitting} disabled={isSubmitting} loadingText="Um instante...">
              Avançar
            </Button>
          </div>
        </div>
      </form>
    </C.Container>
  );
};

export default StepForgotPassword;
