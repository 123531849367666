import React from 'react';
import styled from 'styled-components';
import BVUtilsOld from '@bv/utils-old';

const { colors } = BVUtilsOld;

interface iProps {
  title: JSX.Element;
  isValid: boolean;
}

function ValidatePassword({ title, isValid }: iProps) {
  return (
    <Li>
      <div
        style={{
          width: 16,
        }}>
        {isValid ? <IconValidCheck /> : <IconCheck />}
      </div>

      <>{title}</>
    </Li>
  );
}

export default React.memo(ValidatePassword);

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 7px;
  span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${colors.titleColor};
  }
`;

function IconCheck() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#DEE2E6" stroke="#ABB5BE" />
    </svg>
  );
}

function IconValidCheck() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill="#08C581" />
      <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
