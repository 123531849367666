import styled from "styled-components"

export const Container = styled.div`
  width: 100%;

  .bv-lf-content {
    margin-top: 24px;
    border: 1px solid #08c581;
    border-radius: 4px;
    background: #f3fffa;

    padding: 16px;

    p {
      font-size: 18px;
      color: #0a3622;
    }
  }

  @media (max-width: 768px) {
    .bv-lf-content p {
      font-size: 16px;
    }
  }
`

export const ButtonStyled = styled.button`
  background-color: #2845dc;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
  width: 100%;
  color: #fff;
`

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`
