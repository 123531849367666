import BVComponentsOld from '@bv/components-old';
import BVUtilsOld from '@bv/utils-old';
import BVUtils from '@bv/utils';
import ValidatePassword from '@login-old/components/ValidatePassword';
import { ValidatePassword as ValidatePasswordClass } from '@login-old/functions/ValidatePassword';
import { Card, MainContainer } from '@login-old/styles/common';
import GlobalStyle from '@login-old/styles/globalStyles';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import FormWrapper from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AreaPasswordRules, ContainerStyled } from './styles';

const { api } = BVUtilsOld;
const { Form, Button } = BVComponentsOld;

function RegisterNewPassword() {
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validPassword = new ValidatePasswordClass(password);

  const allRulesIsValid = validPassword.isAllValid() && password == rePassword;
  const [validated, setValidated] = useState(false);

  const [token, setToken] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      setToken(token);
    } else {
      console.log('required token');
    }
  }, []);

  useEffect(() => {
    if (allRulesIsValid) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [allRulesIsValid]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.authApi.redefinePassword(token!, password, rePassword);
      console.log('response ', response);

      const message = response?.data?.message || 'Nova senha cadastrada com sucesso';
      BVUtils.toastHandler({ type: 'success', message });

      navigate('/');
    } catch (error: any) {
      console.error('error: ', error);
      const message = error?.response?.data?.message || 'Expirado!';
      BVUtils.toastHandler({ type: 'error', message });
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return <>Required token</>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cadastre uma nova senha - BV </title>
      </Helmet>
      <GlobalStyle />

      <MainContainer>
        <Card>
          <div className="w-100 mb-3">
            <h1 className="bv-lf-title">Cadastre uma nova senha</h1>
          </div>

          <FormWrapper noValidate validated={validated} onSubmit={onSubmit}>
            <ContainerStyled className="row">
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-md-6">
                    <Form
                      isPasswordInput
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      noValidate
                      required
                      id="password"
                      type="password"
                      label="Senha"
                      placeholder="🤫 Shhhhhhhh..."
                      value={password}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(el) => {
                        setPassword(el.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Form
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                      isPasswordInput
                      noValidate
                      required
                      type="password"
                      label="Repetir Senha"
                      placeholder="🤫 Shhhhhhhh..."
                      value={rePassword}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(el) => {
                        setRePassword(el.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xs-12">
                <div className="row">
                  <div className="col-md-12">
                    <AreaPasswordRules>
                      <ValidatePassword
                        title={
                          <span>
                            A senha deve conter no mínimo <b>8 digitos</b>
                          </span>
                        }
                        isValid={validPassword.minLength()}
                      />

                      <ValidatePassword
                        title={
                          <span>
                            A senha deve conter pelo menos <b>um número</b>
                          </span>
                        }
                        isValid={validPassword.containNumber()}
                      />

                      <ValidatePassword
                        title={
                          <span>
                            A senha deve conter pelo menos um <b>caractere especial (@!$-_%&*#)</b>{' '}
                          </span>
                        }
                        isValid={validPassword.containsSpecialChars()}
                      />
                      <ValidatePassword title={<span>As senhas devem ser iguais</span>} isValid={allRulesIsValid} />
                    </AreaPasswordRules>
                  </div>
                </div>
              </div>

              <div className="col-xs-12">
                <Button className="i-btn-finish" loadingText="Um instante..." type="submit" loading={loading} disabled={!allRulesIsValid || loading}>
                  Salvar nova senha
                </Button>
              </div>
            </ContainerStyled>
          </FormWrapper>
        </Card>
      </MainContainer>
    </>
  );
}

export default RegisterNewPassword;
