/* eslint-disable */

export const REGEX_FULLNAME =
  /^([a-zA-Zà-úÀ-Ú]{2,})+\s+([a-zA-Zà-úÀ-Ú\s]{1,})+$/;

export const REGEX_CPF =
  /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
export const REGEX_CNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
export const REGEX_PASSPORT = /^[a-zA-Z0-9]{6,12}$/;
// export const REGEX_RNM = /^[A-Z]\d{6}-\d$/

//telefone no formato sem - (sem traço), ex: 11971972424
export const REGEX_TELEFONE = /\([1-9]\d\)\s9?\d{4}\d{4}/;

export const REGEX_CEP = /^([\d]{2})([\d]{3})-([\d]{3})/;
export const REGEX_EMAIL = /^\S+@\S+$/i;

// 2022-12-13
export const REGEX_DATE =
  /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;

/*
  ---- REGEX_PASSWORD ----
  No mínimo 8 caracteres
  Letras maiúsculas e minúsculas
  Pelo menos 1 número
  Pelo menos 1 caracter especial

*/
export const REGEX_PASSWORD =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,50}$/;
