import { api } from './api';
import { AxiosResponse } from 'axios';
import { TUser } from '../../../../../types/user';

export type PostSignInDto = {
  username: string;
  password?: string;
  companyId: string;
  ssoToken?: string;
  recaptchaToken?: string;
};

export type PostSignInResponse = {
  user: TUser;
  accessToken: string;
};

export type GetPreLoginResponse = {
  tokenResetPassword?: string;
};

export function preLoginService(email: string, recaptchaToken: string): Promise<AxiosResponse<GetPreLoginResponse>> {
  return api.get(`/v2/users/pre-login`, { params: { email, recaptchaToken } });
}

export function signInService(body: PostSignInDto): Promise<AxiosResponse<PostSignInResponse>> {
  return api.post(`/v2/users/login`, body);
}
